<template>
  <div>
    <the-default-header />
    <v-main>
      <router-view />
    </v-main>
    <the-default-footer />
  </div>
</template>

<script>
export default {
  name: 'TheDefaultView',
  components: {
    TheDefaultHeader: () => import('./components-default/TheDefaultHeader'),
    TheDefaultFooter: () => import('./components-default/TheDefaultFooter'),
  }
};
</script>
