var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"the-wallet-header"},[_c('v-row',{staticClass:"d-flex align-center py-2 pb-md-4 pb-lg-2 px-1"},[_c('v-col',{class:[
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
          ? 'set-fixed-height'
          : '',
        'd-flex align-center justify-space-between'
      ],attrs:{"cols":"12","md":"8","lg":"8","no-gutters":"","dense":""}},[(!_vm.promoOver && !_vm.isOfflineApp)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"party-popper-container ml-2 mr-3 d-flex pa-3",staticStyle:{"filter":"invert(1)"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/icon-party-popper.png"),"width":"20px","height":"20px"}})]),_c('div',{staticClass:"d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-start align-md-center align-lg-center align-xl-center"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-2 textMedium--text font-weight-bold"},[_vm._v(" Buy & sell crypto with 0% fees ")]),_c('mew-button',{staticClass:"d-md-none d-lg-none d-xl-none",attrs:{"title":"Buy crypto","btn-size":"medium"},nativeOn:{"click":function($event){return _vm.buyCryptoNow.apply(null, arguments)}}})],1),_c('div',[_c('span',{staticClass:"mew-label textMedium--text mr-2 margin-one-off d-none d-md-inline d-lg-inline d-xl-inline"},[_vm._v("Promo ends in:")]),_c('span',{staticClass:"font-weight-medium time-container textMedium--text mew-label mr-1 margin-one-off pa-1"},[_vm._v(" "+_vm._s(_vm.daysLeft)+" "+_vm._s(_vm.dayText)+" ")]),_c('span',{staticClass:"font-weight-medium time-container textMedium--text mew-label margin-one-off pa-1"},[_vm._v(" "+_vm._s(_vm.hoursLeft)+" h ")])])])]):(_vm.promoOver && !_vm.isOfflineApp && _vm.network.type.canBuy)?_c('div',{staticClass:"eth-banner d-flex"},[_c('div',{staticClass:"mr-5"},[_c('mew6-white-sheet',{staticClass:"pa-3"},[_c('v-icon',{attrs:{"color":"blackBg"}},[_vm._v(" mdi-bank ")])],1)],1),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"mew-body font-weight-bold textDark--text"},[_vm._v(" You can now buy crypto with low fees ")]),_c('span',{class:[
              _vm.$vuetify.breakpoint.md ||
              _vm.$vuetify.breakpoint.lg ||
              _vm.$vuetify.breakpoint.xl
                ? ''
                : 'py-2',
              'mew-body textMedium--text'
            ]},[_vm._v("Enjoy 0.9% fee when you select ‘Bank account’ as payment method. "),_c('span',{staticClass:"greenPrimary--text font-weight-bold cursor--pointer",on:{"click":_vm.buyCryptoNow}},[_vm._v("Buy crypto now.")])])])]):_vm._e()]),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"ml-auto d-flex align-center justify-end",attrs:{"cols":"4"}},[_c('div',{staticClass:"align-center d-none d-lg-block"},[(_vm.online)?_c('notification-overlay',{attrs:{"invert-icon":_vm.$vuetify.theme.dark}}):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }