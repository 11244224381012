<template>
  <div class="mew-component-fix--unstoppable-domain">
    <div class="d-flex align-center">
      <buy-overlay :open="buyOverlay" :close="closeBuyOverlay" />
      <div
        class="cursor--pointer font-weight-bold mr-4"
        @click="buyOverlay = true"
      >
        Buy Domain Overlay
      </div>
      <add-owned-domain-overlay :open="addOverlay" :close="closeAddOverlay" />
      <div
        class="cursor--pointer font-weight-bold mr-4"
        @click="addOverlay = true"
      >
        Add Owned Domain
      </div>
      <transfer-domain-overlay
        :open="transferOverlay"
        :close="closeTransferOverlay"
      />
      <div
        class="cursor--pointer font-weight-bold mr-4"
        @click="transferOverlay = true"
      >
        Transfer Domain
      </div>
    </div>
    <mew6-white-sheet>
      <mew-banner :text-obj="topBanner" :banner-img="BG" />
      <mew-tabs :items="tabs" has-underline>
        <template #tabContent1>
          <v-sheet color="transparent" max-width="700px" class="mx-auto py-12">
            <div class="mb-5">
              <div class="mb-3">
                <div class="mew-heading-3 font-weight-bold">
                  Find your blockchain domain
                </div>
              </div>
              <div class="d-flex align-start">
                <mew-input
                  :has-clear-btn="true"
                  right-label=".ctypto"
                  label="Domain name"
                  placeholder=" "
                  class="mr-3 flex-grow-1"
                />
                <mew-button
                  :has-full-width="false"
                  btn-size="xlarge"
                  title="Check availability"
                />
              </div>
            </div>
            <div>
              <div class="mb-3">
                <div class="mew-heading-3 font-weight-bold">Results</div>
              </div>
              <v-card flat color="greyLight" class="pa-10">
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <div class="mew-heading-1 mb-2">mewwallet.crypto</div>
                    <div>
                      <span class="font-weight-medium">0.0823234234</span>
                      ETH ($40.00)
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <div class="mew-heading-3 greenPrimary--text mr-6">
                      Available
                    </div>
                    <mew-button title="Buy"></mew-button>
                  </div>
                </div>
              </v-card>
              <div class="py-2"></div>
              <v-card flat color="greyLight" class="pa-10">
                <div class="d-flex align-center justify-space-between mb-8">
                  <div class="mew-heading-1">elitewallet.crypto</div>
                  <div class="mew-heading-3 orange--text">Unavailable</div>
                </div>

                <v-divider class="mb-5" />

                <v-list-item
                  v-for="(r, key) in results"
                  :key="key"
                  two-line
                  class="px-0"
                >
                  <v-list-item-content>
                    <div
                      class="mew-body font-weight-medium textDark--text mb-2"
                    >
                      {{ r.label }}:
                    </div>
                    <div class="mew-body textDark--text">
                      {{ r.value }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>

            <div class="py-10"></div>

            <div>
              <v-card
                flat
                color="greyLight"
                class="d-flex align-center justify-space-between pa-10 mb-10"
              >
                <div class="mew-heading-1">mewwallet.crypto</div>
                <div>
                  <span class="font-weight-medium">0.0341234234 ETH</span>
                  ($40.00)
                </div>
              </v-card>

              <v-sheet color="transparent" max-width="400px" class="mx-auto">
                <div class="d-flex align-center justify-space-between mb-5">
                  <div class="mew-heading-1">Pay with Crypto</div>
                  <div class="font-weight-medium greenPrimary--text">
                    Pay with Credit Card
                  </div>
                </div>

                <v-card
                  outlined
                  color="greyLight"
                  class="pa-4 d-flex align-center justify-space-between bordered-red"
                  style="border-color: var(--v-redPrimary-base) !important"
                >
                  <div class="d-flex align-center">
                    <img
                      src="@/assets/images/currencies/icon-eth-blue.svg"
                      alt="Crypto"
                    />
                    <div class="font-weight-medium ml-3">
                      0.0341234234
                      <span class="greenPrimary--text">ETH</span>
                    </div>
                  </div>
                  <v-icon class="greenPrimary--text">mdi-check-circle</v-icon>
                </v-card>

                <div class="redPrimary--text mt-3 mb-7 font-weight-medium">
                  Insufficient balance.
                  <a
                    v-if="network.type.canBuy"
                    class="text-decoration--underline"
                    @click="openBuySell"
                  >
                    Buy more {{ network.type.name }}
                  </a>
                </div>

                <v-card
                  flat
                  color="greyLight"
                  class="mt-3 pa-4 d-flex align-center justify-space-between"
                >
                  <div class="d-flex align-center">
                    <img
                      src="@/assets/images/icons/icon-fiat-white.svg"
                      alt="Fiat"
                    />
                    <div class="font-weight-medium ml-3">
                      40.23
                      <span class="greenPrimary--text">USD</span>
                    </div>
                  </div>
                  <v-icon>mdi-check-circle-outline</v-icon>
                </v-card>

                <div class="d-flex justify-center mt-5">
                  <mew-button
                    title="Back"
                    btn-style="outline"
                    btn-size="xlarge"
                    class="mr-3"
                  />
                  <mew-button title="Pay" btn-size="xlarge" />
                </div>
              </v-sheet>
            </div>
          </v-sheet>
        </template>
        <template #tabContent2>
          <div class="pa-12">
            <div class="d-flex align-center justify-space-between mb-7">
              <h3>My domains <span class="font-weight-regular">(1)</span></h3>
              <mew-button
                btn-style="outline"
                title="+ Add domain you own"
                btn-size="large"
              />
            </div>
            <div>
              <mew-expand-panel :panel-items="myDomains">
                <template #panelBody1>
                  <div class="px-5">
                    <div class="header-block bg_datablock">
                      <v-row>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <div>Registrant</div>
                            <mew-blockie
                              width="25px"
                              height="25px"
                              class="mx-3"
                            />
                            <div class="monospace truncate">
                              0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <div>Controller</div>
                            <mew-blockie
                              width="25px"
                              height="25px"
                              class="mx-3"
                            />
                            <div class="monospace truncate">
                              0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <div
                        class="d-flex align-center justify-space-between border-bottom py-5 px-0"
                      >
                        <div class="mew-heading-3">
                          What do you want to do with the domain?
                        </div>
                        <div>Parent - ETH</div>
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-5">
                        <v-row>
                          <v-col
                            v-for="(f, key) in domainFunctions"
                            :key="key"
                            cols="2"
                            class="text-center"
                          >
                            <mew-icon icon-name="ensManager" :img-height="75" />
                            <div>{{ f.label }}</div>
                            <div v-if="f.expire" class="orange--text">
                              <div>Expire at</div>
                              <div>{{ f.expire }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </template>
                <template #panelBody2>
                  <div class="px-5">
                    <div class="header-block bg_datablock">
                      <v-row>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <div>Registrant</div>
                            <mew-blockie
                              width="25px"
                              height="25px"
                              class="mx-3"
                            />
                            <div class="monospace truncate">
                              0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <div>Controller</div>
                            <mew-blockie
                              width="25px"
                              height="25px"
                              class="mx-3"
                            />
                            <div class="monospace truncate">
                              0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <div
                        class="d-flex align-center justify-space-between border-bottom py-5 px-0"
                      >
                        <div class="mew-heading-3">
                          What do you want to do with the domain?
                        </div>
                        <div>Parent - ETH</div>
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-5">
                        <v-row>
                          <v-col
                            v-for="(f, key) in domainFunctions"
                            :key="key"
                            cols="2"
                            class="text-center"
                          >
                            <mew-icon icon-name="ensManager" :img-height="75" />
                            <div>{{ f.label }}</div>
                            <div v-if="f.expire" class="orange--text">
                              <div>Expire at</div>
                              <div>{{ f.expire }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </template>
              </mew-expand-panel>
            </div>
          </div>
        </template>
        <template #tabContent3>
          <v-sheet color="transparent" max-width="700px" class="mx-auto py-12">
            <!-- <div class="addressBlock d-flex align-center mb-7">
              <mew-blockie :address="address" />
              <span class="font-weight-heavy pl-15">{{ address }}</span>
            </div> -->
            <div class="mb-5">
              <div class="mb-3">
                <div class="addressBlock d-flex align-center mb-7">
                  <!-- <mew-blockie :address="address" /> -->
                  <!-- <span class="mew-heading-4 font-weight-heavy pl-5">{{
                    address
                  }}</span> -->
                </div>
              </div>
              <div class="d-flex flex-column justify-space-between">
                <div class="mew-heading-3 mb-2">
                  Reverse Resolution for Address
                </div>
                <div class="d-flex justify-space-between">
                  <!-- <mew-select
                    :value="selectedAddress"
                    :filter-placeholder="address"
                    @input="setAddress"
                  >
                  </mew-select> -->
                  <mew-input
                    :value="address"
                    :show-blockie="true"
                    :hide-clear-btn="true"
                    :is-read-only="true"
                    :loading="true"
                    :error-messages="inputErrorMessage"
                    label="Wallet Address"
                    class="mr-3 mt-10 flex-grow-1"
                    @input="setAddress"
                  />
                  <div class="d-flex flex-column justify-space-between">
                    <mew-button
                      :has-full-width="false"
                      btn-size="xlarge"
                      title="Reverse TokenId"
                      class="mb-1"
                      @click.native="reverseTokenId()"
                    />
                    <mew-button
                      :has-full-width="false"
                      btn-size="xlarge"
                      title="Reverse Url"
                      class="mt-1"
                      @click.native="reverseUrl()"
                    />
                  </div>
                </div>
              </div>
              <div v-if="hasTokenIdResults" class="d-flex flex-column mt-2">
                <span class="mew-heading-3 font-weight-heavy"
                  >Reverse Resolve TokenId by Address Result:
                </span>
                <span class="mew-heading-4 font-weight-heavy mt-2">
                  {{ reverseTokenIdResults }}
                </span>
              </div>
              <div v-if="hasUrlResults" class="d-flex flex-column mt-2">
                <span class="mew-heading-3 font-weight-heavy"
                  >Reverse Resolve Url by Address Result:
                </span>
                <span class="mew-heading-4 font-weight-heavy mt-2">
                  {{ reverseUrlResults }}
                </span>
              </div>
            </div>
          </v-sheet>
        </template>
      </mew-tabs>
    </mew6-white-sheet>
  </div>
</template>

<script>
import BG from '@/assets/images/backgrounds/bg-unstoppable-domain.jpg';
import buyOverlay from './components/UnstoppableDomainBuyOverlay';
import addOwnedDomainOverlay from './components/UnstoppableAddOwnedDomainOverlay';
import transferDomainOverlay from './components/UnstoppableTransferDomainOverlay';
import buyMore from '@/core/mixins/buyMore.mixin.js';
import { mapGetters, mapState } from 'vuex';
import Resolution from '@unstoppabledomains/resolution';
import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';

export default {
  components: {
    buyOverlay,
    addOwnedDomainOverlay,
    transferDomainOverlay
  },
  mixins: [buyMore],
  data() {
    return {
      buyOverlay: false,
      addOverlay: false,
      transferOverlay: false,
      inputtedAddress: null,
      reverseTokenIdResults: '',
      reverseUrlResults: '',
      hasUrlResults: false,
      hasTokenIdResults: false,
      isLoading: false,
      tabs: [
        { name: 'Buy domain' },
        { name: 'Manage domain' },
        { name: 'Reverse Resolver' }
      ],
      domainFunctions: [
        { label: 'Transfer Domain' },
        { label: 'Renew Domain', expire: '07/21/2020' },
        { label: 'ENS Configurations' },
        { label: 'Manage Multicoins' },
        { label: 'Manage Text Records' },
        { label: 'Upload Website' },
        { label: 'Return Funds' }
      ],
      myDomains: [
        {
          name: 'mewdev009.eth',
          toggleTitle: '',
          colorTheme: 'greyLight',
          warningBadge: {
            color: 'orangePrimary',
            text: 'Expire soon'
          }
        },
        {
          name: 'mewdev008.eth',
          toggleTitle: '',
          colorTheme: 'greyLight',
          warningBadge: {
            color: 'orangePrimary',
            text: 'Expire soon'
          }
        }
      ],
      BG: BG,
      topBanner: {
        title: 'Unstoppable Domain',
        toggleTitle:
          'Replace cryptocurrency addresses with a human readable name.'
      },
      results: [
        {
          label: 'Labelhash (mewwallet)',
          value: '0x527b9715d99aCfB7E1b01C6C864DC8402fC6C864DC8402f2a3C3b'
        },
        {
          label: 'Namehash (mewwallet.eth)',
          value: '0x527b9715d99aCfB7E1b01C6C864DC8402fC6C864DC8402f2a3C3b'
        },
        {
          label: 'Owner',
          value: '0x527b9715d99aCfB7E1b01C6C864DC8402f2a3C3b'
        },
        {
          label: 'Multi-coin',
          value: 'None'
        },
        {
          label: 'Wallet address',
          value: '0x502e119bbf7b476a449a67fdabfa35f486194c9a'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('global', ['network']),
    ...mapState('wallet', ['balance', 'web3', 'instance', 'address']),
    inputErrorMessage() {
      if (this.inputtedAddress === '') {
        return `Address input cannot be empty.`;
      }
      return '';
    }
  },
  methods: {
    async reverseTokenId() {
      this.isLoading = true;
      const address = this.inputtedAddress;
      const resolution = new Resolution();
      try {
        const results = await resolution
          .reverseTokenId(address)
          .then(tokenId => {
            const tokenIdRes = tokenId;
            this.reverseTokenIdResults =
              address + ' ' + `reversed to` + ' ' + tokenIdRes;
          });
        this.isLoading = false;
        this.hasTokenIdResults = true;
        return results;
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    async reverseUrl() {
      this.isLoading = true;
      const address = this.inputtedAddress;
      const resolution = new Resolution();
      try {
        const results = await resolution
          .reverse(address, {
            location: 'UNSLayer2'
          })
          .then(domain => {
            const domainRes = domain;
            this.reverseUrlResults =
              address + ' ' + `reversed to` + ' ' + domainRes;
          });
        this.isLoading = false;
        this.hasUrlResults = true;
        return results;
      } catch (e) {
        Toast(e, {}, ERROR);
      }
    },
    setAddress(value) {
      this.inputtedAddress = value;
    },
    closeBuyOverlay() {
      this.buyOverlay = false;
    },
    closeAddOverlay() {
      this.addOverlay = false;
    },
    closeTransferOverlay() {
      this.transferOverlay = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.set-button {
  margin-left: 10px;
}
</style>
