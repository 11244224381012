import { render, staticRenderFns } from "./HomeFeaturesDapps.vue?vue&type=template&id=be50ebbe&scoped=true&"
import script from "./HomeFeaturesDapps.vue?vue&type=script&lang=js&"
export * from "./HomeFeaturesDapps.vue?vue&type=script&lang=js&"
import style0 from "./HomeFeaturesDapps.vue?vue&type=style&index=0&id=be50ebbe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be50ebbe",
  null
  
)

export default component.exports